import React from "react"
import Hero, { CarouselItem, imgItem } from "../components/Layout/Hero"
import { Subheading, Heading } from "../components/Layout/Hero/style"
import bgImage from "../../static/img/HowWeDoIt.png"
import Alberta from "../../static/img/Alberta.svg"
import Laroche from "../../static/img/laroche.png"
import Waterloo from "../../static/img/UniversityOfWaterloo.png"
import Voith from "../../static/img/voith.svg"
import styled from "styled-components"
import { Button } from "../components/Button/style"
import SEO from "../components/SEO"
import { navigate } from "gatsby"

const imgList: imgItem[] = [
  {
    url: Waterloo,
    height: 30,
    href: "https://uwaterloo.ca/",
  },
  {
    url: Alberta,
    height: 25,
    href: "https://albertainnovates.ca/",
  },
  {
    url: Voith,
    height: 20,
    href: "https://voith.com/",
  },
  {
    url: Laroche,
    height: 28,
    href: "https://www.laroche.edu/",
  },
]

const carouselItem: CarouselItem[] = [
  {
    backgroundImg: "COx4hNLNgX8",
    isVideo: true,
    subheading: {
      text: "Tangho is constantly investing in the research and development of competitive, sustainable products by partnering with highly specialized, academic institutions and industry players.",
      mobileFontAlign: "left",
      desktopFontAlign: "left",
      fontColor: "#FCFBFB",
      mobileFontSize: "14px",
      desktopFontSize: "24px",
      mobileMargin: "15px 0px 30px 0px",
      desktopMargin: "0",
      mobileLineHeight: 18,
      desktopLineHeight: 32,
      maxWidth: "650px",
    },
    // imgList,
    heading: {
      mobileMargin: "120px 0 0 0",
      desktopMargin: "180px 0 24px 0",
      fontColor: "#FCFBFB",
      text: "HOW WE DO IT",
      mobileFontAlign: "left",
      desktopFontAlign: "center",
      mobileFontSize: "36px",
      mobileLineHeight: 36,
      desktopFontSize: "96px",
      desktopLineHeight: 96,
      maxWidth: "650px",
    },
  },
]

export const Container = styled.div`
  padding: 32px 16px 32px 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${({
    theme: {
      main: { offWhite },
    },
  }) => offWhite};
`

const HowWeDoIt = () => {
  return (
    <>
      <SEO
        title="Tangho - How We Do it"
        description="Tangho is constantly investing in the research and development of competitive, sustainable products by partnering with highly specialized, academic institutions and industry players."
        image={bgImage}
        url="/research"
      />
      <Hero carouselItems={carouselItem} mobileHeight={556} />
      <Container>
        <Heading
          maxWidth="600px"
          mobileFontAlign="left"
          desktopFontAlign="left"
          desktopMargin="auto"
          padded="32px 0 0 0"
        >
          Cultivation
        </Heading>
        <Subheading
          fontColor={"#020202"}
          mobileFontAlign="left"
          desktopFontAlign="left"
          mobileFontSize="14px"
          mobileLineHeight={18}
          padded="32px 0px"
          desktopFontSize="24px"
          desktopLineHeight={31}
          maxWidth="600px"
        >
          With over 2,000 hectares dedicated exclusively to the culture of hemp,
          we are the largest producers in Latin America. Our breeding operation
          is already developing proprietary seeds for different applications.
          <br />
          <br />
          Each growth cycle represents an opportunity to aggregate data and
          study different species' performance and behaviors; furthering our
          knowledge about Hemp to provide only the best quality products.
          <Button
            onClick={() => navigate("/hemp")}
            isWhite={true}
            mobileMargin="50px 0 0 0"
          >
            Why Hemp?
          </Button>
        </Subheading>
      </Container>
    </>
  )
}

export default HowWeDoIt
